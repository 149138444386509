var $ = jQuery.noConflict();

$(document).ready(function($) {
    "use strict";

        setTimeout(function(){
            $('body').addClass('loaded');
        }, 1000);

        $('#slider2').revolution({
            sliderType: "standard",
            sliderLayout: "auto",
            disableProgressBar: "on",
            delay: 5000,
            spinner: "off",
            gridwidth: 1230,
            gridheight: 650,
            navigation: {
                mouseScrollNavigation: "off",
                onHoverStop: "off",
                bullets: {
                    enable:true,
                    hide_onmobile:true,
                    hide_under:778,
                    style:"hermes",
                    hide_onleave:false,
                    direction:"horizontal",
                    h_align:"center",
                    v_align:"bottom",
                    h_offset:0,
                    v_offset:100,
                    space:5,
                    tmp:''
                },
            },

        });

        /*
        |--------------------------------------------------------------------------
        | Skew
        |--------------------------------------------------------------------------
        */
        function skew_position() {
            var window_width = jQuery(window).width();
            var skew_square_height = 130;
            jQuery(".skew_appended").height(skew_square_height + "px").css("bottom", "-" + skew_square_height / 2 + "px").css("-webkit-backface-visibility", "hidden !important");
            jQuery(".skew_prepended").height(skew_square_height + "px").css("top", "-" + (skew_square_height / 2 + 1) + "px").css("-webkit-backface-visibility", "hidden !important");
        }
        skew_position();

});
